import {
  alignTypes,
  fittingTypes,
  htmlTag,
  upscaleMethods,
} from '../../helpers/imageServiceConstants';
import { isWEBP } from '../../helpers/imageServiceUtils';
import { populateGlobalFeatureSupport } from '../../helpers/populateFeatureSupport';
import { getData, getPlaceholder } from './api';
import {
  getScaleToFitImageURL,
  getScaleToFillImageURL,
  getCropImageURL,
  wixStaticWithMedia,
} from '../../sdk';

populateGlobalFeatureSupport();

const sdk = {
  getScaleToFitImageURL,
  getScaleToFillImageURL,
  getCropImageURL,
};

export * from '../../types';

const STATIC_MEDIA_URL = wixStaticWithMedia;

export {
  STATIC_MEDIA_URL,
  alignTypes,
  fittingTypes,
  getData,
  getPlaceholder,
  htmlTag,
  populateGlobalFeatureSupport,
  sdk,
  upscaleMethods,
  isWEBP,
};
