import type {
  HTMLTag,
  AlignmentParam,
  AlignType,
  FittingType,
  FileType,
  ImageQuality,
  ImageQualityData,
  ImageTransformFiltersOption,
  TransformType,
  UpscaleMethod,
  ImageDataAttributes,
} from '../types';

/**
 * image service api version
 */
const API_VERSION = 'v1';

/**
the maximum retina factor
 */
const MAX_DEVICE_PIXEL_RATIO = 2;

/**
 site BG legacy max width x height
 */
const DSKTP_MAX_BG_SITE_LEGACY_WIDTH = 1920;
const DSKTP_MAX_BG_SITE_LEGACY_HEIGHT = 1920;
const MOBILE_MAX_BG_SITE_LEGACY_WIDTH = 1000;
const MOBILE_MAX_BG_SITE_LEGACY_HEIGHT = 1000;

/**
 * Enum string values of requested image fitting types
 * Note: TILE_HORIZONTAL, TILE_VERTICAL, FIT_AND_TILE are supported for legacy purposes but are not exposed
 * in the documentation because they should not be exposed for new features
 */
const fittingTypes: Record<string, FittingType> = {
  SCALE_TO_FILL: 'fill',
  SCALE_TO_FIT: 'fit',
  STRETCH: 'stretch',
  ORIGINAL_SIZE: 'original_size',
  TILE: 'tile',
  TILE_HORIZONTAL: 'tile_horizontal',
  TILE_VERTICAL: 'tile_vertical',
  FIT_AND_TILE: 'fit_and_tile',
  LEGACY_STRIP_TILE: 'legacy_strip_tile',
  LEGACY_STRIP_TILE_HORIZONTAL: 'legacy_strip_tile_horizontal',
  LEGACY_STRIP_TILE_VERTICAL: 'legacy_strip_tile_vertical',
  LEGACY_STRIP_SCALE_TO_FILL: 'legacy_strip_fill',
  LEGACY_STRIP_SCALE_TO_FIT: 'legacy_strip_fit',
  LEGACY_STRIP_FIT_AND_TILE: 'legacy_strip_fit_and_tile',
  LEGACY_STRIP_ORIGINAL_SIZE: 'legacy_strip_original_size',
  LEGACY_ORIGINAL_SIZE: 'actual_size',
  LEGACY_FIT_WIDTH: 'fitWidth',
  LEGACY_FIT_HEIGHT: 'fitHeight',
  LEGACY_FULL: 'full',
  LEGACY_BG_FIT_AND_TILE: 'legacy_tile',
  LEGACY_BG_FIT_AND_TILE_HORIZONTAL: 'legacy_tile_horizontal',
  LEGACY_BG_FIT_AND_TILE_VERTICAL: 'legacy_tile_vertical',
  LEGACY_BG_NORMAL: 'legacy_normal',
};

/**
 * Enum string values of image transform types as passed to the image service api
 */
const transformTypes: Record<string, TransformType> = {
  FIT: 'fit',
  FILL: 'fill',
  FILL_FOCAL: 'fill_focal',
  CROP: 'crop',
  LEGACY_CROP: 'legacy_crop',
  LEGACY_FILL: 'legacy_fill',
};

/**
 * Enum string values of requested image align types
 * @type {{CENTER: string, RIGHT: string, LEFT: string, TOP: string, BOTTOM: string, TOP_RIGHT: string, TOP_LEFT: string, BOTTOM_RIGHT: string, BOTTOM_LEFT: string}}
 */
const alignTypes: Record<string, AlignType> = {
  CENTER: 'center',
  TOP: 'top',
  TOP_LEFT: 'top_left',
  TOP_RIGHT: 'top_right',
  BOTTOM: 'bottom',
  BOTTOM_LEFT: 'bottom_left',
  BOTTOM_RIGHT: 'bottom_right',
  LEFT: 'left',
  RIGHT: 'right',
};

/**
 * Enum 9Grid alignment to focal point
 */
const ALIGN_TYPE_TO_FOCAL_POINT = {
  [alignTypes.CENTER]: { x: 0.5, y: 0.5 },
  [alignTypes.TOP_LEFT]: { x: 0, y: 0 },
  [alignTypes.TOP_RIGHT]: { x: 1.0, y: 0 },
  [alignTypes.TOP]: { x: 0.5, y: 0 },
  [alignTypes.BOTTOM_LEFT]: { x: 0, y: 1.0 },
  [alignTypes.BOTTOM_RIGHT]: { x: 1.0, y: 1.0 },
  [alignTypes.BOTTOM]: { x: 0.5, y: 1.0 },
  [alignTypes.RIGHT]: { x: 1.0, y: 0.5 },
  [alignTypes.LEFT]: { x: 0, y: 0.5 },
};

/**
 * Enum string values of image align types as passed to the image service
 */
const alignTypesMap: Record<AlignType, AlignmentParam> = {
  center: 'c',
  top: 't',
  top_left: 'tl',
  top_right: 'tr',
  bottom: 'b',
  bottom_left: 'bl',
  bottom_right: 'br',
  left: 'l',
  right: 'r',
};

/**
 * Enum string values of html tag used to construct the css or svg attributes
 *  @type {{BG: string, IMG: string, SVG: string}}
 */
const htmlTag: Record<string, HTMLTag> = {
  BG: 'bg',
  IMG: 'img',
  SVG: 'svg',
};

/**
 * Enum string values of upscale method
 *  @type {{DEFAULT: string, SUPER: string}}
 */
const upscaleMethods: Record<string, UpscaleMethod> = {
  AUTO: 'auto',
  CLASSIC: 'classic',
  SUPER: 'super',
};

/**
 *  api values of upscale method
 *  @type {{default: number, super: number}}
 */
const upscaleMethodsValues = {
  classic: 1,
  super: 2,
};

/**
 * default unsharp mask values
 *  @type {{radius: number, amount: number, threshold: number}}
 */
const defaultUSM = {
  radius: '0.66',
  amount: '1.00',
  threshold: '0.01',
};

/**
 * default empty data
 */
const emptyData = {
  uri: '',
  css: {
    img: {},
    container: {},
  },
  attr: {
    img: {},
    container: {},
  },
  transformed: false,
} as ImageDataAttributes;

const MAX_TRANSFORMED_IMAGE_WIDTH = 5000;
const MAX_TRANSFORMED_IMAGE_HEIGHT = 5000;
const SAFE_TRANSFORMED_AREA =
  MAX_TRANSFORMED_IMAGE_WIDTH * MAX_TRANSFORMED_IMAGE_HEIGHT;

/**
 * super res machine learning upscale factor models -
 * @type {number[]}
 */
const SUPER_UPSCALE_MODELS = [1.5, 2, 4];

/**
 * image qualities
 */
const imageScaleDefaults: Record<ImageQuality, ImageQualityData> = {
  HIGH: {
    size: 1400 * 1400,
    quality: 90,
    maxUpscale: 1,
  },
  MEDIUM: {
    size: 600 * 600,
    quality: 85,
    maxUpscale: 1,
  },
  LOW: {
    size: 400 * 400,
    quality: 80,
    maxUpscale: 1.2,
  },
  TINY: {
    size: 0,
    quality: 80,
    maxUpscale: 1.4,
  },
};

/**
 * image quality
 * @type {{HIGH: string, MEDIUM: string, LOW: string, TINY: string}}
 */
const imageQuality: Record<ImageQuality, ImageQuality> = {
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
  TINY: 'TINY',
};

/**
 * image filters
 * @type {{CONTRAST: string, BRIGHTNESS: string, SATURATION: string, HUE: string, BLUR: string}}
 */
const imageFilters: Record<string, keyof ImageTransformFiltersOption> = {
  CONTRAST: 'contrast',
  BRIGHTNESS: 'brightness',
  SATURATION: 'saturation',
  HUE: 'hue',
  BLUR: 'blur',
};

const fileType: Record<string, FileType> = {
  JPG: 'jpg',
  JPEG: 'jpeg',
  JPE: 'jpe',
  PNG: 'png',
  WEBP: 'webp',
  WIX_ICO_MP: 'wix_ico_mp',
  WIX_MP: 'wix_mp',
  GIF: 'gif',
  SVG: 'svg',
  UNRECOGNIZED: 'unrecognized',
};

const supportedExtensions = [
  fileType.JPG,
  fileType.JPEG,
  fileType.JPE,
  fileType.PNG,
  fileType.GIF,
  fileType.WEBP,
];

export {
  alignTypes,
  alignTypesMap,
  transformTypes,
  fittingTypes,
  htmlTag,
  upscaleMethods,
  upscaleMethodsValues,
  defaultUSM,
  emptyData,
  imageQuality,
  imageFilters,
  imageScaleDefaults,
  fileType,
  supportedExtensions,
  DSKTP_MAX_BG_SITE_LEGACY_WIDTH,
  MOBILE_MAX_BG_SITE_LEGACY_WIDTH,
  DSKTP_MAX_BG_SITE_LEGACY_HEIGHT,
  MOBILE_MAX_BG_SITE_LEGACY_HEIGHT,
  SAFE_TRANSFORMED_AREA,
  SUPER_UPSCALE_MODELS,
  MAX_DEVICE_PIXEL_RATIO,
  ALIGN_TYPE_TO_FOCAL_POINT,
  API_VERSION,
};
