import {
  CloseModal,
  GetAccessToken,
  Navigate,
  ObserveState,
  OpenModal,
  SDK,
  ShowToast,
  Host,
} from './types';

export const createDashboardSDK = ({ channel }: Host): SDK => {
  /**
   * Displays a toast notification when invoked from a dashboard page or widget within a page.
   *
   * Use the `config` parameter to:
   * - Control the toast's appearance.
   * - Set callback functions to run when the user sees or clicks on the toast.
   * - Display a clickable call-to-action that is displayed inside the toast.
   *
   * Only one toast can be shown at a time. Requests to display toasts may be queued and the toast may not be displayed immediately, if another toast is currently shown.
   *
   * @remarks When the `timeout` parameter is set to `none` (a toast that the user needs to close manually, or removed via code) the toast is rendered into the page layout and pushes the rest of the page down. When `timeout` is set to `normal`, the toast appears on top of other content on the page.
   *
   * @param config - Toast configuration options.
   */
  const showToast: ShowToast = (...args) => {
    const handle = channel.showToast(...args);
    const remove = () => Promise.resolve(handle).then((h) => h.remove());
    return { remove };
  };

  /**
   * Allows to receive contextual state and environmental information of the dashboard.
   * The callback function provided to `observeState` receives the state data and triggered upon the initialization of the dashboard component and each time the data is updated.
   *
   * Common use cases for this method
   * - A dashboard page in the backoffice, receiving environment data from the host (f.e. the user's locale).
   * - A widget rendered within the dashboard, receiving contextual data from the page that is hosting it.
   * - A modal opened from a dashboard page or from a widget, receiving data from either.
   *
   * @param observer - Callback function for receiving state data that takes two parameters:
   *                    `state`: The data that is sent to your component by your host, who is responsible for rendering it.
   *                    `envData`: General information about the user's present environment.
   * @typeParam S - A generic type parameter that indicates the data that is sent to your component by your host, who is responsible for rendering the component.
   * @typeParam T - General information about the user's environment. Currently only the locale data is provided.
   */
  const observeState: ObserveState = (...args) => {
    const handle = (channel.observeState as ObserveState)(
      ...args,
    ) as ReturnType<Host['channel']['observeState']>; // this temporary casting is needed because it seems that typescript can't handle this by itself.
    const disconnect = () =>
      Promise.resolve(handle).then((h) => h.disconnect());
    return { disconnect };
  };

  /**
   * Navigates the user to another page in the dashboard.
   * If an invalid pageId value is passed to this function, a "Page Not Found" page will be displayed in the dashboard.
   *
   * @param pageId - ID of the page to navigate to.
   */
  const navigate: Navigate = (...args) => {
    return channel.navigate(...args);
  };

  /**
   * Opens a modal component.
   *
   * This function returns a promise that resolves when the modal is closed.
   * The object that the promise resolves to contains any data passed to the {@link CloseModal} function used to close the modal.
   *
   * @param componentId - ID of the component to show in the modal, as defined in Wix Developer Center.
   * @param componentParams - Optional. Custom data to pass into the component, the component may consume this data using {@link observeState}.
   */
  const openModal: OpenModal = (...args) => {
    const handle = channel.openModal(...args);
    return { modalClosed: Promise.resolve(handle).then((h) => h.modalClosed) };
  };

  /**
   * Closes the currently opened modal.
   *
   * @param closeData - Optional. Data to pass to the modal's opener. This data is returned by openModal once the modal is closed. It must be Serializable data, which means it can be cloned using the structured clone algorithm. For example, you cannot pass function callbacks in this parameter.
   */
  const closeModal: CloseModal = (closeData) => {
    if (closeData === undefined) {
      return channel.closeModal();
    }
    const filteredArg = JSON.parse(JSON.stringify(closeData));
    return channel.closeModal(filteredArg);
  };

  const getAccessToken: GetAccessToken = () => {
    return channel.getAccessToken();
  };

  return {
    showToast,
    observeState,
    navigate,
    openModal,
    closeModal,
    getAccessToken,
  };
};
