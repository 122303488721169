import {
  alignTypes,
  alignTypesMap,
  fileType,
  fittingTypes,
  imageQuality,
  imageScaleDefaults,
  transformTypes,
  upscaleMethods,
  upscaleMethodsValues,
  ALIGN_TYPE_TO_FOCAL_POINT,
  MAX_DEVICE_PIXEL_RATIO,
  SUPER_UPSCALE_MODELS,
  SAFE_TRANSFORMED_AREA,
} from './imageServiceConstants';
import { last } from './utils';
import type {
  FileType,
  AlignType,
  FittingType,
  TransformType,
  UpscaleMethod,
  ImageQuality,
  ImageTransformData,
  ImageTransformOptions,
  ImageTransformSource,
  ImageTransformTarget,
} from '../types';

const SUPPORTED_IMAGE_EXTENSIONS: Partial<FileType>[] = [
  fileType.PNG,
  fileType.JPEG,
  fileType.JPG,
  fileType.JPE,
  fileType.WIX_ICO_MP,
  fileType.WIX_MP,
  fileType.WEBP,
];

const JPG_EXTENSIONS: Partial<FileType>[] = [
  fileType.JPEG,
  fileType.JPG,
  fileType.JPE,
];

/**
 * checks if image type is supported
 * @param {string}     uri      image source uri
 *
 * @returns {boolean}
 */
function isImageTypeSupported(uri: string) {
  return SUPPORTED_IMAGE_EXTENSIONS.includes(
    getFileExtension(uri) as Partial<FileType>,
  );
}

/**
 * check request integrity
 * @param {FittingType}             fittingType         imageService.fittingTypes
 * @param {ImageTransformSource}    src
 * @param {ImageTransformTarget}    target
 *
 * @returns {boolean}
 */
function isValidRequest(
  fittingType: FittingType,
  src: ImageTransformSource,
  target: ImageTransformTarget,
) {
  return (
    target &&
    src &&
    !isUrlEmptyOrNone(src.id) &&
    Object.values(fittingTypes).includes(fittingType)
  );
}

/**
 * check if image transform is supported for source image
 */
function isImageTransformApplicable(
  uri: string,
  hasAnimation?: boolean,
  allowWEBPTransform?: boolean,
) {
  return (
    !isNotTransformableWEBP(uri, hasAnimation, allowWEBPTransform) &&
    isImageTypeSupported(uri) &&
    !isExternalUrl(uri)
  );
}

/**
 * returns true if image is of WEBP type and is animated or WEBP transform is not allowed
 */
function isNotTransformableWEBP(
  uri: string,
  hasAnimation?: boolean,
  allowWEBPTransform: boolean = false,
) {
  return isWEBP(uri) && (hasAnimation || !allowWEBPTransform);
}

/**
 * returns true if image is of JPG type
 * @param {string}  uri
 *
 * @returns {boolean}
 */
function isJPG(uri: string) {
  return JPG_EXTENSIONS.includes(getFileExtension(uri) as Partial<FileType>);
}

/**
 * returns true if image is of PNG type
 * @param {string}  uri
 *
 * @returns {boolean}
 */
function isPNG(uri: string) {
  return getFileExtension(uri) === fileType.PNG;
}

/**
 * returns true if image is of webP type
 * @param {string}  uri
 *
 * @returns {boolean}
 */
function isWEBP(uri: string) {
  return getFileExtension(uri) === fileType.WEBP;
}

/**
 * returns true if the url starts with http, https, // or data
 * @param {string}  url
 *
 * @returns {boolean}
 */
function isExternalUrl(url: string) {
  return /(^https?)|(^data)|(^\/\/)/.test(url);
}

/**
 * returns true if the url empty or none string
 * @param {string}  url
 *
 * @returns {boolean}
 */
function isUrlEmptyOrNone(url: string) {
  return !url || !url.trim() || url.toLowerCase() === 'none';
}

/**
 * returns search bot true or false as indicated in options
 * @param {ImageTransformOptions}   options
 *
 * @returns {boolean}
 */
function isSEOBot(options?: ImageTransformOptions) {
  return options?.isSEOBot ?? false;
}

// https://jira.wixpress.com/browse/WEED-12667
// const illegalChars = ['/', '\\', '#', '^', '?', '{', '}', '<', '>', '|', '`', '“', ':', '"'].map(encodeURIComponent)
const ILLEGAL_CHARS = ['/', '\\', '?', '<', '>', '|', '“', ':', '"'].map(
  encodeURIComponent,
);
const URL_SAFE_ILLEGAL_CHARS = ['\\.', '\\*'];
const ILLEGAL_CHARS_REPLACEMENT = '_';

/**
 * returns source image file name (no extension)
 * @param {string}     uri      image source uri
 * @param {string}     [name]   optional image source name
 *
 * @returns {string}
 */
function getFileName(uri: string, name?: string) {
  const beforeLeadingSlashRegexp = /\/(.*?)$/;
  const fileExtensionRegexp = /\.([^.]*)$/;
  const illegalCharsRegex = new RegExp(
    `(${ILLEGAL_CHARS.concat(URL_SAFE_ILLEGAL_CHARS).join('|')})`,
    'g',
  );

  // if name is a non empty string, remove only supported extension if exists and url encode the string
  if (name && name.length) {
    let fileName = name;

    const extension = name.match(fileExtensionRegexp);

    if (
      extension &&
      SUPPORTED_IMAGE_EXTENSIONS.includes(extension[1] as Partial<FileType>)
    ) {
      fileName = name.replace(fileExtensionRegexp, '');
    }

    return encodeURIComponent(fileName).replace(
      illegalCharsRegex,
      ILLEGAL_CHARS_REPLACEMENT,
    );
  }

  // else, trim any preceding media structure from the uri string (like "media/" etc.) and remove extension
  const trimmed = uri.match(beforeLeadingSlashRegexp);
  const fileName = trimmed ? trimmed[1] : uri;
  return fileName.replace(fileExtensionRegexp, '');
}

/**
 * returns source image file name (no extension)
 * @param {string}     uri      image source uri
 *
 * @returns {FileType}
 */
function getFileType(uri: string) {
  if (isJPG(uri)) {
    return fileType.JPG;
  } else if (isPNG(uri)) {
    return fileType.PNG;
  } else if (isWEBP(uri)) {
    return fileType.WEBP;
  }
  return fileType.UNRECOGNIZED;
}

/**
 * returns source image file extension
 * @param {string}     uri      image source uri
 *
 * @returns {string}
 */
function getFileExtension(uri: string) {
  const splitURI = /[.]([^.]+)$/.exec(uri);
  return ((splitURI && /[.]([^.]+)$/.exec(uri)![1]) || '').toLowerCase();
}

/**
 * returns scale factor needed if FIT fitting
 * @param {number}  sWidth
 * @param {number}  sHeight
 * @param {number}  dWidth
 * @param {number}  dHeight
 *
 * @returns {number}
 */
function getFitScaleFactor(
  sWidth: number,
  sHeight: number,
  dWidth: number,
  dHeight: number,
) {
  return Math.min(dWidth / sWidth, dHeight / sHeight);
}

/**
 * returns scale factor needed if FILL fitting
 * @param {number}  sWidth
 * @param {number}  sHeight
 * @param {number}  dWidth
 * @param {number}  dHeight
 *
 * @returns {number}
 */
function getFillScaleFactor(
  sWidth: number,
  sHeight: number,
  dWidth: number,
  dHeight: number,
) {
  return Math.max(dWidth / sWidth, dHeight / sHeight);
}

/**
 * returns scale factor source target
 * @param {number}  sWidth
 * @param {number}  sHeight
 * @param {number}  dWidth
 * @param {number}  dHeight
 * @param {string}  transformType
 *
 * @returns {number}
 */
function getScaleFactor(
  sWidth: number,
  sHeight: number,
  dWidth: number,
  dHeight: number,
  transformType: TransformType,
) {
  let scaleFactor;

  if (transformType === transformTypes.FILL) {
    scaleFactor = getFillScaleFactor(sWidth, sHeight, dWidth, dHeight);
  } else if (transformType === transformTypes.FIT) {
    scaleFactor = getFitScaleFactor(sWidth, sHeight, dWidth, dHeight);
  } else {
    scaleFactor = 1;
  }

  return scaleFactor;
}

/**
 * get calculated scale factor , width and height while considering wixmp image transform dimension limits
 * @param sWidth
 * @param sHeight
 * @param dWidth
 * @param dHeight
 * @param transformType
 * @returns {{scaleFactor: *, width: *, height: *}}
 */
function getSafeTransformData(
  sWidth: number,
  sHeight: number,
  dWidth: number,
  dHeight: number,
  transformType: TransformType,
) {
  let scaleFactor;
  // defaults for FILL transform type
  let width = dWidth;
  let height = dHeight;

  // calculate safe image transformed area
  scaleFactor = getScaleFactor(sWidth, sHeight, dWidth, dHeight, transformType);

  if (transformType === transformTypes.FIT) {
    width = sWidth * scaleFactor;
    height = sHeight * scaleFactor;
  }

  // adjust target width & height & scaleFactor
  if (width && height && width * height > SAFE_TRANSFORMED_AREA) {
    const dimensionScaleFactor = Math.sqrt(
      SAFE_TRANSFORMED_AREA / (width * height),
    );
    width *= dimensionScaleFactor;
    height *= dimensionScaleFactor;
    // get the new scale factor
    scaleFactor = getScaleFactor(sWidth, sHeight, width, height, transformType);
  }

  return {
    scaleFactor,
    width,
    height,
  };
}

/**
 * returns the destination rectangle
 * @param {number}                  sWidth
 * @param {number}                  sHeight
 * @param {TransformType}           transformType
 * @param {ImageTransformTarget}    target
 * @param {number}                  dpr - device pixel ratio
 * @param {UpscaleMethod}           upscaleMethod
 *
 * @returns {ImageTransformData & {upscaleMethodValue: number}}
 */
function getTransformData(
  sWidth: number,
  sHeight: number,
  transformType: TransformType,
  target: ImageTransformTarget,
  dpr: number,
  upscaleMethod: UpscaleMethod,
): ImageTransformData & { upscaleMethodValue: number } {
  // use target dimension is src not provided
  sWidth = sWidth || target.width;
  sHeight = sHeight || target.height;

  // adjust image transform values considering server side transform limitations and performance
  const { scaleFactor, width, height } = getSafeTransformData(
    sWidth,
    sHeight,
    target.width * dpr,
    target.height * dpr,
    transformType,
  );

  // adjust image transform values to optimizing upsacle quality and payload
  return getOptimizedTransformData(
    sWidth,
    sHeight,
    width,
    height,
    upscaleMethod,
    scaleFactor,
    transformType,
  );
}

/**
 * converts 9 grid alignment to Focal point position
 * @param {string}  [alignment]
 *
 * @returns {x:number,y:number}
 */

function getFocalPointFrom9GridAlignment(alignment = alignTypes.CENTER) {
  return ALIGN_TYPE_TO_FOCAL_POINT[alignment];
}

/**
 * returns overlapping rectangle where sRect
 * id aligned (according to alignment) within dRect
 * @param {{ width: number; height: number }} sRect rect 1
 * @param {{ width: number, height: number }} dRect rect 2
 * @param {{x: number, y: number}|undefined}  sFP   source image focal point
 * @param {string}                            alignment
 *
 * @returns {{x: number, y: number, width: number, height: number}}
 */
function getAlignedRect(
  sRect: { width: number; height: number },
  dRect: { width: number; height: number },
  sFP: { x?: number; y?: number } | undefined,
  alignment?: string,
) {
  const fp =
    getFocalPoint(sFP) ||
    getFocalPointFrom9GridAlignment(alignment as AlignType);
  const x = Math.max(
    0,
    Math.min(
      sRect.width - dRect.width,
      (fp.x as number) * sRect.width - dRect.width / 2,
    ),
  );
  const y = Math.max(
    0,
    Math.min(
      sRect.height - dRect.height,
      (fp.y as number) * sRect.height - dRect.height / 2,
    ),
  );

  // rect
  return {
    x,
    y,
    width: Math.min(sRect.width, dRect.width),
    height: Math.min(sRect.height, dRect.height),
  };
}

/**
 * returns overlapping rectangle between sRect and dRect
 * @param {object}      sRect         rect 1
 * @param {object}      dRect         rect 2
 *
 * @returns {{x:number,y:number,width:number, height:number} || null}
 */
function getOverlappingRect(
  sRect: { width: number; height: number },
  dRect: { width: number; height: number; x: number; y: number },
) {
  const width = Math.max(
    0,
    Math.min(sRect.width, dRect.x + dRect.width) - Math.max(0, dRect.x),
  );
  const height = Math.max(
    0,
    Math.min(sRect.height, dRect.y + dRect.height) - Math.max(0, dRect.y),
  );

  const isValidRect =
    width && height && (sRect.width !== width || sRect.height !== height);

  // return overlapping sRect/dRect rectangle(x, y, width, height)
  return isValidRect
    ? {
        x: Math.max(0, dRect.x),
        y: Math.max(0, dRect.y),
        width,
        height,
      }
    : null;
}

/**
 * returns pixel aspect ratio value
 * @param {ImageTransformTarget}    target
 *
 * @returns {number}
 */
function getDevicePixelRatio(target: ImageTransformTarget) {
  return Math.min(target.pixelAspectRatio || 1, MAX_DEVICE_PIXEL_RATIO);
}

/**
 * returns target alignment value
 * @param {ImageTransformTarget}    target
 *
 * @returns {string}
 */
function getAlignment(target: ImageTransformTarget) {
  return (
    (target.alignment && alignTypesMap[target.alignment]) ||
    alignTypesMap[alignTypes.CENTER as AlignType]
  );
}

/**
 * returns the focal point value, if no focal point passed use alignment
 * @param {{x: number, y: number}|undefined} focalPoint
 */
function getFocalPoint(focalPoint: { x?: number; y?: number } | undefined) {
  let fp;

  if (
    focalPoint &&
    typeof focalPoint.x === 'number' &&
    !isNaN(focalPoint.x) &&
    typeof focalPoint.y === 'number' &&
    !isNaN(focalPoint.y)
  ) {
    fp = {
      x: roundToFixed(Math.max(0, Math.min(100, focalPoint.x)) / 100, 2),
      y: roundToFixed(Math.max(0, Math.min(100, focalPoint.y)) / 100, 2),
    };
  }

  return fp;
}

/**
 * returns preferred image quality value
 * @param {number}    imageWidth
 * @param {number}    imageHeight
 *
 * @returns {number}
 */
function getPreferredImageQuality(imageWidth: number, imageHeight: number) {
  return imageScaleDefaults[getImageQualityKey(imageWidth, imageHeight)]
    .quality;
}

/**
 * returns the scale descriptor of CLASSIC upscale method
 * @param sWidth
 * @param sHeight
 * @returns {{optimizedScaleFactor: number, upscaleMethodValue: number, forceUSM: boolean}}
 */
function getClassicScaleData(sWidth: number, sHeight: number) {
  const imageKey = getImageQualityKey(sWidth, sHeight);

  return {
    optimizedScaleFactor: imageScaleDefaults[imageKey].maxUpscale,
    upscaleMethodValue: upscaleMethodsValues.classic,
    forceUSM: false,
  };
}

/**
 * returns the scale descriptor of AUTO upscale method
 * @param sWidth
 * @param sHeight
 * @returns {{optimizedScaleFactor: number, upscaleMethodValue: number, forceUSM: boolean}}
 */
function getAutoScaleData(sWidth: number, sHeight: number) {
  const imageKey = getImageQualityKey(sWidth, sHeight);

  return {
    optimizedScaleFactor: imageScaleDefaults[imageKey].maxUpscale,
    upscaleMethodValue: upscaleMethodsValues.classic,
    forceUSM: false,
  };
}

/**
 * returns the scale descriptor of SUPER upscale method
 * @param scaleFactor
 * @returns {{optimizedScaleFactor: number, upscaleMethodValue: number, forceUSM: boolean}}
 */
function getSuperScaleData(scaleFactor: number) {
  return {
    optimizedScaleFactor: last(SUPER_UPSCALE_MODELS),
    upscaleMethodValue: upscaleMethodsValues.super,
    forceUSM: !(
      SUPER_UPSCALE_MODELS.includes(scaleFactor) ||
      scaleFactor > last(SUPER_UPSCALE_MODELS)
    ),
  };
}

/**
 * returns upscale descriptor object
 * @param {number}    sWidth
 * @param {number}    sHeight
 * @param {string}    upscaleMethod
 * @param {number}    scaleFactor
 *
 * @returns  {{maxScale: number, upscaleMethodValue: number, forceUSM: boolean}}
 */
function getOptimizedScaleData(
  sWidth: number,
  sHeight: number,
  scaleFactor: number,
  upscaleMethod: UpscaleMethod,
) {
  if (upscaleMethod === 'auto') {
    return getAutoScaleData(sWidth, sHeight);
  } else if (upscaleMethod === 'super') {
    return getSuperScaleData(scaleFactor);
  }

  // assuming 'classic' method
  return getClassicScaleData(sWidth, sHeight);
}

/**
 * returns optimized upscale data, considering requested upscale method , optimize upscale for best quality and bandwidth
 * @param {number}    sWidth
 * @param {number}    sHeight
 * @param {number}    tWidth
 * @param {number}    tHeight
 * @param {UpscaleMethod}    upscaleMethod
 * @param {number}    scaleFactor
 * @param {TransformType}    transformType
 *
 * @returns  {ImageTransformData}
 */
function getOptimizedTransformData(
  sWidth: number,
  sHeight: number,
  tWidth: number,
  tHeight: number,
  upscaleMethod: UpscaleMethod,
  scaleFactor: number,
  transformType: TransformType,
): ImageTransformData & { upscaleMethodValue: number } {
  const { optimizedScaleFactor, upscaleMethodValue, forceUSM } =
    getOptimizedScaleData(sWidth, sHeight, scaleFactor, upscaleMethod);

  let width = tWidth;
  let height = tHeight;

  if (scaleFactor <= optimizedScaleFactor) {
    // target upscale within limits or downscale
    return {
      width,
      height,
      scaleFactor,
      upscaleMethodValue,
      forceUSM,
      cssUpscaleNeeded: false,
    };
  }
  // limited upscale
  switch (transformType) {
    case transformTypes.FILL:
      width = tWidth! * (optimizedScaleFactor / scaleFactor);
      height = tHeight! * (optimizedScaleFactor / scaleFactor);
      break;
    case transformTypes.FIT:
      width = sWidth * optimizedScaleFactor;
      height = sHeight * optimizedScaleFactor;
      break;
    default:
      break;
  }
  // adjust transform values
  return {
    width,
    height,
    scaleFactor: optimizedScaleFactor,
    upscaleMethodValue,
    forceUSM,
    cssUpscaleNeeded: true,
  };
}

/**
 * returns image quality key
 * @param {number}    imageWidth
 * @param {number}    imageHeight
 *
 * @returns {ImageQuality}
 */
function getImageQualityKey(
  imageWidth: number,
  imageHeight: number,
): ImageQuality {
  const size = imageWidth * imageHeight;

  if (size > imageScaleDefaults[imageQuality.HIGH].size) {
    return imageQuality.HIGH;
  } else if (size > imageScaleDefaults[imageQuality.MEDIUM].size) {
    return imageQuality.MEDIUM;
  } else if (size > imageScaleDefaults[imageQuality.LOW].size) {
    return imageQuality.LOW;
  }
  return imageQuality.TINY;
}

/**
 * return the actual rounded dimension of a scaled rectangle
 * @param sWidth
 * @param sHeight
 * @param tWidth
 * @param tHeight
 * @param transformType
 * @returns {{width: number, height: number}}
 */
function getDimension(
  sWidth: number,
  sHeight: number,
  tWidth: number,
  tHeight: number,
  transformType: TransformType,
) {
  const scaleFactor = getScaleFactor(
    sWidth,
    sHeight,
    tWidth,
    tHeight,
    transformType,
  );
  return {
    width: Math.round(sWidth * scaleFactor),
    height: Math.round(sHeight * scaleFactor),
  };
}

/**
 * rounds number n digit precision and converts to string
 * @param {number}      value
 * @param {number}      precision
 *
 * @returns {string}
 */
function roundToFixed(value: number, precision: number) {
  const truncatePrecision = Math.pow(10, precision || 0);

  return ((value * truncatePrecision) / truncatePrecision).toFixed(precision);
}

/**
 * get normalize scale method
 * @param {ImageTransformOptions} [options]
 * @returns {UpscaleMethod}
 */
function getUpscaleString(options?: ImageTransformOptions) {
  if (!options || !options.upscaleMethod) {
    return upscaleMethods.AUTO;
  }
  return (
    upscaleMethods[options.upscaleMethod.toUpperCase()] || upscaleMethods.AUTO
  );
}

export {
  getAlignedRect,
  getAlignment,
  getDevicePixelRatio,
  getDimension,
  getFileExtension,
  getFileName,
  getFileType,
  getFocalPoint,
  getOverlappingRect,
  getPreferredImageQuality,
  getScaleFactor,
  getTransformData,
  getUpscaleString,
  isExternalUrl,
  isImageTransformApplicable,
  isImageTypeSupported,
  isSEOBot,
  isValidRequest,
  isPNG,
  isWEBP,
  roundToFixed,
};
