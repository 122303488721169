import { ProviderAPI } from '../types';
import { OpenedChannel } from '@wix/communication-channel';

export const registerHeightReporter = (
  bridge: OpenedChannel<ProviderAPI>,
  container: HTMLElement,
) => {
  const update = () => bridge.updateHeight(container.offsetHeight);
  const resizeObserver = new ResizeObserver(update);
  resizeObserver.observe(container);
  return () => resizeObserver.disconnect();
};
