import type { HostModule, AuthenticationStrategy } from '@wix/sdk-types';
import type { CreateHost, Host, SDK } from './types';

export const createDashboardModule = ({
  createDashboardSDK,
  createHost,
}: {
  createDashboardSDK: (host: Host) => SDK;
  createHost: CreateHost;
}): HostModule<SDK, Host> & {
  host: CreateHost;
  auth: () => AuthenticationStrategy<Host>;
} => {
  return {
    __type: 'host',
    create: (host: Host) => createDashboardSDK(host),
    host: createHost,
    auth: () => {
      let sdk: SDK;
      return {
        getAuthHeaders: async (host) => {
          sdk ||= createDashboardSDK(
            host || createHost({ autoHeightUpdate: false }),
          );
          return {
            headers: {
              Authorization: await sdk.getAccessToken(),
            },
          };
        },
      };
    },
  };
};
