import {
  fittingTypes,
  alignTypes,
  htmlTag,
  upscaleMethods,
} from '../../helpers/imageServiceConstants';
import { isValidRequest } from '../../helpers/imageServiceUtils';
import { populateGlobalFeatureSupport } from '../../helpers/populateFeatureSupport';
import { getTarget, getTransform } from '../transform';
import { getURI } from '../uri';
import type {
  FittingType,
  ImageTransformSource,
  ImageTransformTarget,
  ImageTransformOptions,
} from '../../types';

/**
 * returns image transform uri
 *
 * @param {FittingType}             fittingType         imageServicesTypes.fittingTypes
 * @param {ImageTransformSource}    src                 source image
 * @param {ImageTransformTarget}    target              target component
 * @param {ImageTransformOptions}   [options]           transform options
 *
 * @returns {{uri: string}}
 */
function getData(
  fittingType: FittingType,
  src: ImageTransformSource,
  target: ImageTransformTarget,
  options?: ImageTransformOptions,
): { uri: string } {
  // check if valid request
  if (isValidRequest(fittingType, src, target)) {
    // handle site BG legacy fitting types
    const targetObj = getTarget(fittingType, src, target);
    // parse request and create working OBJ
    const transformObj = getTransform(fittingType, src, targetObj, options);

    return {
      uri: getURI(fittingType, src, targetObj, options || {}, transformObj),
    };
  }

  return { uri: '' };
}

export {
  populateGlobalFeatureSupport,
  getData,
  fittingTypes,
  alignTypes,
  htmlTag,
  upscaleMethods,
};
