import {
  isSEOBot,
  getFileType,
  getFileName,
  getFileExtension,
  getDevicePixelRatio,
  getUpscaleString,
  isImageTransformApplicable,
} from '../helpers/imageServiceUtils';
import { isMobile } from '../helpers/browserFeatureSupport';
import { setTransformParts } from '../helpers/imageTransformParts';
import { setTransformOptions } from '../helpers/imageTransformOptions';
import {
  MOBILE_MAX_BG_SITE_LEGACY_WIDTH,
  DSKTP_MAX_BG_SITE_LEGACY_WIDTH,
  MOBILE_MAX_BG_SITE_LEGACY_HEIGHT,
  DSKTP_MAX_BG_SITE_LEGACY_HEIGHT,
  fittingTypes,
} from '../helpers/imageServiceConstants';
import type {
  FittingType,
  ImageTransformSource,
  ImageTransformTarget,
  ImageTransformOptions,
  ImageTransformObject,
} from '../types';

/**
 * returns image transform data
 *
 * @param {FittingType}             fittingType         imageServicesTypes.fittingTypes
 * @param {ImageTransformSource}    src                 source image
 * @param {ImageTransformTarget}    target              target component
 * @param {ImageTransformOptions}   [options]           transform options
 *
 * @returns {ImageTransformObject}
 */
function getTransform(
  fittingType: FittingType,
  src: ImageTransformSource,
  target: ImageTransformTarget,
  options?: ImageTransformOptions,
): ImageTransformObject {
  const _isSEOBot = isSEOBot(options);
  const fileType = getFileType(src.id);
  const fileName = getFileName(src.id, src.name);
  const devicePixelRatio = _isSEOBot ? 1 : getDevicePixelRatio(target);
  const fileExtension = getFileExtension(src.id);
  const preferredExtension = fileExtension;
  const canTransformImage = isImageTransformApplicable(
    src.id,
    options?.hasAnimation,
    options?.allowWEBPTransform,
  );

  const transformsObj = {
    fileName,
    fileExtension,
    fileType,
    fittingType,
    preferredExtension,
    src: {
      id: src.id,
      width: src.width,
      height: src.height,
      isCropped: false,
    },
    focalPoint: {
      x: src.focalPoint && src.focalPoint.x,
      y: src.focalPoint && src.focalPoint.y,
    },
    parts: [],
    // options - general
    devicePixelRatio,
    quality: 0,
    upscaleMethod: getUpscaleString(options),
    progressive: true,
    watermark: '',
    unsharpMask: {},
    filters: {},
    transformed: canTransformImage,
  };

  if (canTransformImage) {
    setTransformParts(transformsObj, src, target);
    setTransformOptions(transformsObj, options);
  }

  return transformsObj;
}

/**
 * returns target data
 * handle legacy BG site if needed
 *
 * @param {FittingType}             fittingType         imageServicesTypes.fittingTypes
 * @param {ImageTransformSource}    src                 source image
 * @param {ImageTransformTarget}    target              target component
 *
 * @returns {Object}
 */
function getTarget(
  fittingType: FittingType,
  src: ImageTransformSource,
  target: ImageTransformTarget,
) {
  const targetObj = { ...target };
  const _isMobile = isMobile();

  // handle site BG legacy fitting types (desktop & mobile)
  switch (fittingType) {
    case fittingTypes.LEGACY_BG_FIT_AND_TILE:
    case fittingTypes.LEGACY_BG_FIT_AND_TILE_HORIZONTAL:
    case fittingTypes.LEGACY_BG_FIT_AND_TILE_VERTICAL:
    case fittingTypes.LEGACY_BG_NORMAL:
      const maxBGSiteLegacyWidth = _isMobile
        ? MOBILE_MAX_BG_SITE_LEGACY_WIDTH
        : DSKTP_MAX_BG_SITE_LEGACY_WIDTH;
      const maxBGSiteLegacyHeight = _isMobile
        ? MOBILE_MAX_BG_SITE_LEGACY_HEIGHT
        : DSKTP_MAX_BG_SITE_LEGACY_HEIGHT;
      targetObj.width = Math.min(maxBGSiteLegacyWidth, src.width);
      targetObj.height = Math.min(
        maxBGSiteLegacyHeight,
        Math.round(targetObj.width / (src.width / src.height)),
      );
      // for legacy types force htmlTag='bg' and devicePixelRatio=1
      targetObj.pixelAspectRatio = 1;
  }

  return targetObj;
}

export { getTransform, getTarget };
