import { htmlTag } from '../helpers/imageServiceConstants';
import type { ImageTransformObject, ImageTransformTarget } from '../types';
import { get as backgroundAttributes } from './attributes/backgroundAttributes';
import { get as imgAttributes } from './attributes/imgAttributes';
import { get as SVGAttributes } from './attributes/SVGAttributes';

/**
 * get CSS or SVG attributes to be used in the browser
 * @param {ImageTransformObject}    transformsObj    transform parts object
 * @param {ImageTransformTarget}    target
 *
 * @returns object
 */
function getAttributes(
  transformsObj: ImageTransformObject,
  target: ImageTransformTarget,
) {
  let attributesGetter;

  if (target.htmlTag === htmlTag.BG) {
    attributesGetter = backgroundAttributes;
  } else if (target.htmlTag === htmlTag.SVG) {
    attributesGetter = SVGAttributes;
  } else {
    attributesGetter = imgAttributes;
  }

  return attributesGetter(transformsObj, target);
}

export { getAttributes };
