/**
 * Simple templates.
 * Receives a string with es6 ${...} style template arguments and returns a transformed string.
 * @param string
 * @returns {Function}
 */

function template(strings: TemplateStringsArray, ...keys: string[]) {
  return function (...values: Record<string, any>[]) {
    const dict = values[values.length - 1] || {};
    const result = [strings[0]];
    keys.forEach(function (key, i) {
      const value = Number.isInteger(key) ? values[key as any] : dict[key];
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  };
}

/**
 * Get the last element in an array
 * @param array
 * @returns {*}
 */
function last(array: any[]) {
  return array[array.length - 1];
}

export { last, template };
