const globalFeaturesSupportObj: Record<
  string,
  boolean | Record<string, boolean>
> = {
  /**
   * @type {object<boolean>}
   */
  isMobile: false,
};

const getFeature = function (feature: string) {
  return globalFeaturesSupportObj[feature];
};

const setFeature = function (
  feature: string,
  value: boolean | Record<string, boolean>,
) {
  globalFeaturesSupportObj[feature] = value;
};

export { getFeature, setFeature };
