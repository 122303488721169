import { getData, populateGlobalFeatureSupport } from '../api/uri/index';
import {
  fittingTypes,
  htmlTag,
  alignTypes,
} from '../helpers/imageServiceConstants';
import type { ImageTransformOptions } from '../types';

const wixStatic = 'https://static.wixstatic.com/';
const wixStaticWithMedia = 'https://static.wixstatic.com/media/';
const HAS_MEDIA_PREFIX_RE = /^media\//i;

const devicePixelRatio =
  typeof window !== 'undefined' ? window.devicePixelRatio : 1;

const getWixStaticURL = (uri: string) =>
  HAS_MEDIA_PREFIX_RE.test(uri)
    ? `${wixStatic}${uri}`
    : `${wixStaticWithMedia}${uri}`;

const getURL = (uri: string, options?: ImageTransformOptions) => {
  const baseHostURL = options && options.baseHostURL;
  return baseHostURL ? `${baseHostURL}${uri}` : getWixStaticURL(uri);
};

function getScaleToFitImageURL(
  relativeUrl: string,
  sourceWidth: number,
  sourceHeight: number,
  targetWidth: number,
  targetHeight: number,
  options?: ImageTransformOptions,
) {
  const data = getData(
    fittingTypes.SCALE_TO_FIT,
    {
      id: relativeUrl,
      width: sourceWidth,
      height: sourceHeight,
      name: options && options.name,
    },
    {
      width: targetWidth,
      height: targetHeight,
      htmlTag: htmlTag.IMG,
      alignment: alignTypes.CENTER,
      pixelAspectRatio: devicePixelRatio,
    },
    options,
  );

  return getURL(data.uri, options);
}

function getScaleToFillImageURL(
  relativeUrl: string,
  sourceWidth: number,
  sourceHeight: number,
  targetWidth: number,
  targetHeight: number,
  options?: ImageTransformOptions,
) {
  const data = getData(
    fittingTypes.SCALE_TO_FILL,
    {
      id: relativeUrl,
      width: sourceWidth,
      height: sourceHeight,
      name: options && options.name,
      focalPoint: {
        x: options && options.focalPoint && options.focalPoint.x,
        y: options && options.focalPoint && options.focalPoint.y,
      },
    },
    {
      width: targetWidth,
      height: targetHeight,
      htmlTag: htmlTag.IMG,
      alignment: alignTypes.CENTER,
      pixelAspectRatio: devicePixelRatio,
    },
    options,
  );

  return getURL(data.uri, options);
}

function getCropImageURL(
  relativeUrl: string,
  sourceWidth: number,
  sourceHeight: number,
  cropX: number,
  cropY: number,
  cropWidth: number,
  cropHeight: number,
  targetWidth: number,
  targetHeight: number,
  options?: ImageTransformOptions,
) {
  const data = getData(
    fittingTypes.SCALE_TO_FILL,
    {
      id: relativeUrl,
      width: sourceWidth,
      height: sourceHeight,
      name: options && options.name,
      crop: {
        x: cropX,
        y: cropY,
        width: cropWidth,
        height: cropHeight,
      },
    },
    {
      width: targetWidth,
      height: targetHeight,
      htmlTag: htmlTag.IMG,
      alignment: alignTypes.CENTER,
      pixelAspectRatio: devicePixelRatio,
    },
    options,
  );

  return getURL(data.uri, options);
}

export {
  populateGlobalFeatureSupport,
  getScaleToFitImageURL,
  getScaleToFillImageURL,
  getCropImageURL,
  wixStatic,
  wixStaticWithMedia,
};
