import { CreateHost, ProviderAPI } from './types';
import { getOriginFromUrl } from './helpers/getOriginFromUrl';
import { registerHeightReporter } from './helpers/registerHeightReporter';
import { open } from '@wix/communication-channel';
import { isWixOrigin } from './helpers/isWixOrigin';

/**
 * Create new dashboard communication channel.
 * @param config - SDK channel initialization params.
 */
export const createHost: CreateHost = function (config = {}) {
  const origin = config.origin || getOriginFromUrl();
  const postMessage =
    config.postMessage || window.parent.postMessage.bind(window.parent);

  if (!isWixOrigin(origin)) {
    throw new Error(
      `Wix Dashboard SDK: Unable to establish a connection with the Wix dashboard. Please ensure that you are running your app within the Wix dashboard.`,
    );
  }

  const { channel, close } = open<ProviderAPI>({ postMessage, origin });
  const closeMethods = [close];

  if (config.autoHeightUpdate === undefined || config.autoHeightUpdate) {
    const element = config.autoHeightElement || document.documentElement;
    const clearHeightReport = registerHeightReporter(channel, element);
    closeMethods.push(() => clearHeightReport());
  }

  return {
    channel,
    close: () => closeMethods.forEach((method) => method()),
  };
};
