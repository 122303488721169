import { setFeature } from './imageServiceFeatureSupportObject';

/**
 * Populate the global feature support object with browser specific values
 */
function populateGlobalFeatureSupport() {
  if (typeof window !== 'undefined' && typeof navigator !== 'undefined') {
    const isSmallScreen =
      window.matchMedia && window.matchMedia('(max-width: 767px)').matches;
    const isMobileAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
    // set is mobile
    setFeature('isMobile', isSmallScreen && isMobileAgent);
  }
}

export { populateGlobalFeatureSupport };
