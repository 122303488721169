import { proxy } from 'comlink';

const proxyCallbackMarker = '__proxyCallbackId__wixSDK__';
const proxyPromiseMarker = '__proxyPromiseId__wixSDK__';
type SerializedReturnValue = {
  proxies: ProxiesCollector;
  clonables: any;
};
export type ProxiesCollector = {
  callbacks: { [key: string]: Function };
  promises: { [key: string]: Promise<any> };
};

export const serializeAllMethodsIn = <T extends {}>(sdk: T) => {
  return wrapMethodsWithProxy(sdk, (originalMethod) => {
    return async (...args: any) => {
      const [proxies, argsNoProxies] = extractProxies(args);
      const originalResult = await originalMethod(
        proxy(proxies),
        argsNoProxies,
      );
      return deserializeReturnValue(originalResult);
    };
  });
};

export const deserializeAllMethodsIn = <T extends {}>(api: T) => {
  return wrapMethodsWithProxy(api, (originalMethod) => {
    return (proxies: ProxiesCollector, args: any[]) => {
      const argsWithProxies = insertProxies(proxies, args);
      const originalRun = originalMethod(...argsWithProxies);
      return serializeReturnValue(originalRun);
    };
  });
};

const serializeReturnValue = async (
  value: any,
): Promise<SerializedReturnValue> => {
  const [proxies, clonables] = extractProxies(await value);
  return proxy({ proxies, clonables });
};

const deserializeReturnValue = async (value: any) => {
  return insertProxies(value?.proxies, await value?.clonables);
};

const extractProxies = (data: any): [ProxiesCollector, any] => {
  const walker = (proxies: ProxiesCollector, val: any): any => {
    if (isPromise(val)) {
      const proxyId = uniqueId();
      proxies.promises[proxyId] = val;
      return { [proxyPromiseMarker]: proxyId };
    }

    if (isFunction(val)) {
      const proxyId = uniqueId();
      proxies.callbacks[proxyId] = (
        innerProxies: ProxiesCollector,
        argsAsArray: any[],
      ) => {
        const argsWithProxies = insertProxies(innerProxies, argsAsArray);
        const exec = val(...argsWithProxies);
        return serializeReturnValue(exec);
      };
      return { [proxyCallbackMarker]: proxyId };
    }

    if (Array.isArray(val)) {
      return val.map((i: any) => walker(proxies, i));
    }
    if (isObject(val)) {
      return mapObjectValues(val, (i: any) => walker(proxies, i));
    }

    return val;
  };

  const proxies: ProxiesCollector = { promises: {}, callbacks: {} };
  const dataWithoutProxies = walker(proxies, data);
  return [proxies, dataWithoutProxies];
};

const insertProxies = (proxies: ProxiesCollector, data: any): any => {
  if (Array.isArray(data)) {
    return data.map((i: any) => insertProxies(proxies, i));
  }

  if (isObject(data) && data.hasOwnProperty(proxyPromiseMarker)) {
    const promiseId = (data as any)[proxyPromiseMarker];
    return proxies.promises[promiseId];
  }

  if (isObject(data) && data.hasOwnProperty(proxyCallbackMarker)) {
    const callbackId = (data as any)[proxyCallbackMarker];
    // passing args in spread syntax to comlink proxy generates problem when transpiling to old JS version
    // so pass it as array and handle it on the deserialization level:
    return async (...args: any) => {
      const [innerProxies, argsNoProxies] = extractProxies(args);
      const exec = await proxies.callbacks[callbackId](
        proxy(innerProxies),
        argsNoProxies,
      );
      return deserializeReturnValue(exec);
    };
  }

  if (isObject(data)) {
    return mapObjectValues(data, (i: any) => insertProxies(proxies, i));
  }

  return data;
};

const wrapMethodsWithProxy = <T extends Record<string, Function>>(
  obj: T,
  callback: (originalMethod: Function) => Function,
) => {
  return new Proxy<T>(obj, {
    get(target: any, prop: any) {
      if (!target[prop]) {
        return () => {
          throw new Error(
            `Serialization error occurred while accessing ${prop} property of ${target}`,
          );
        };
      }
      return callback(target[prop]);
    },
  });
};

const isPromise = (val: any): val is Promise<any> => val instanceof Promise;
const isObject = (val: any): val is object =>
  val && typeof val === 'object' && !isPromise(val);
const isFunction = (val: any): val is Function => typeof val === 'function';
const mapObjectValues = (obj: object, callback: Function) => {
  const mapEntries = ([key, value]: [string, any]) => [key, callback(value)];
  return Object.fromEntries(Object.entries(obj).map(mapEntries));
};
const uniqueId = (() => {
  let counter = 0;
  return () => ++counter;
})();
