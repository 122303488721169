import { emptyData } from '../../helpers/imageServiceConstants';
import {
  isImageTransformApplicable,
  isValidRequest,
} from '../../helpers/imageServiceUtils';
import { getAttributes } from '../../engines/attributes';
import { getTransform, getTarget } from '../transform';
import { getURI } from '../uri';
import { getSrcset } from '../srcset';
import type {
  ImageDataAttributes,
  FittingType,
  ImageTransformSource,
  ImageTransformTarget,
  ImagePlaceholderTransformTarget,
  ImageTransformOptions,
} from '../../types';
import {
  getScaledDimensions,
  getBlurValue,
  getCSSOverrides,
  validateTargetDimensions,
  getIsFakeTile,
  getConvertedFitting,
} from '../../helpers/imagePlaceholderUtils';

const PLACEHOLDER_IMG_CSS_OVERRIDES = { width: '100%', height: '100%' };

/**
 * returns image _placeholder_ transform uri and the
 * needed css for alignment and positioning
 *
 * @param {FittingType}             fittingType imageServicesTypes.fittingTypes
 * @param {ImageTransformSource}    src         image
 * @param {ImagePlaceholderTransformTarget}    target      target dimensions
 * @param {ImageTransformOptions} [options]   environment / transformation  options
 *
 * @returns {ImageDataAttributes}
 */
function getPlaceholder(
  fittingType: FittingType,
  src: ImageTransformSource,
  target: ImagePlaceholderTransformTarget,
  options: ImageTransformOptions = {},
): ImageDataAttributes {
  if (!isValidRequest(fittingType, src, target as ImageTransformTarget)) {
    return emptyData;
  }

  const {
    autoEncode = true,
    isSEOBot,
    shouldLoadHQImage,
    hasAnimation,
  } = options;

  const alwaysAllowWEBPTransform = true

  const canTransformImage = isImageTransformApplicable(
    src.id,
    hasAnimation,
    alwaysAllowWEBPTransform,
  );

  if (!canTransformImage || shouldLoadHQImage) {
    /* Either:
     * 1. Transform isn't applicable so target is original size applied with fluid CSS
     * 2. User asked for an HQ image, so they must have supplied target size (width/height)
     */
    return getData(fittingType, src, target as ImageTransformTarget, {
      ...options,
      autoEncode,
      useSrcset: canTransformImage,
    });
  }

  // If there aren't dimensions, we make them up
  const newTarget = {
    ...target,
    ...validateTargetDimensions(src, target),
  } as ImageTransformTarget;

  const { alignment, htmlTag } = newTarget;
  const isFakeTile = getIsFakeTile(fittingType, src, newTarget);

  const scaledDimensions = getScaledDimensions(
    fittingType,
    src,
    newTarget,
    isSEOBot,
  );

  const blur = getBlurValue(newTarget.width, fittingType, isSEOBot);

  const convertedFittingType = getConvertedFitting(fittingType, isFakeTile);

  const overrideCSS = getCSSOverrides(
    fittingType,
    src,
    target,
    alignment as string,
  );

  const { uri } = getData(
    convertedFittingType as FittingType,
    src,
    {
      ...scaledDimensions,
      alignment,
      htmlTag,
    },
    {
      autoEncode,
      filters: blur ? { blur } : {},
      hasAnimation,
      allowWEBPTransform: alwaysAllowWEBPTransform,
    },
  );

  const { attr = {}, css } = getData(
    fittingType,
    src,
    {
      ...newTarget,
      alignment,
      htmlTag,
    },
    {},
  );

  css!.img = css!.img || {};
  css!.container = css!.container || {};
  Object.assign(css!.img, overrideCSS.img, PLACEHOLDER_IMG_CSS_OVERRIDES);
  Object.assign(css!.container, overrideCSS.container);

  return { uri, css, attr, transformed: true };
}

/**
 * returns image transform uri and the
 * needed css for alignment and positioning
 *
 * @param {FittingType}             fittingType         imageServicesTypes.fittingTypes
 * @param {ImageTransformSource}    src                 source image
 * @param {ImageTransformTarget}    target              target component
 * @param {ImageTransformOptions}   [options]          transform options
 *
 * @returns {ImageDataAttributes}
 */
function getData(
  fittingType: FittingType,
  src: ImageTransformSource,
  target: ImageTransformTarget,
  options?: ImageTransformOptions,
) {
  let data: ImageDataAttributes = {} as ImageDataAttributes;

  // check if valid request
  if (isValidRequest(fittingType, src, target)) {
    // handle site BG legacy fitting types if needed
    const targetObj = getTarget(fittingType, src, target);
    // parse request and create working OBJ
    const transformObj = getTransform(fittingType, src, targetObj, options);

    data.uri = getURI(fittingType, src, targetObj, options, transformObj);

    if (options?.useSrcset) {
      data.srcset = getSrcset(fittingType, src, targetObj, options, data);
    }

    // set the CSS or the SVG property
    Object.assign(data, getAttributes(transformObj, targetObj), {
      transformed: transformObj.transformed,
    });
  } else {
    // empty data
    data = emptyData;
  }

  // return data to be used in html
  return data;
}

export { getData, getPlaceholder };
