import { template } from '../helpers/utils';
import {
  imageFilters,
  transformTypes,
  API_VERSION,
  fileType,
} from '../helpers/imageServiceConstants';
import type { ImageTransformObject, ImageTransformObjectPart } from '../types';

// transform templates
const fitTemplate = template`fit/w_${'width'},h_${'height'}`;
const fillTemplate = template`fill/w_${'width'},h_${'height'},al_${'alignment'}`;
const fillFocalTemplate = template`fill/w_${'width'},h_${'height'},fp_${'focalPointX'}_${'focalPointY'}`;
const cropTemplate = template`crop/x_${'x'},y_${'y'},w_${'width'},h_${'height'}`;

// legacy templates
const legacyCropTemplate = template`crop/w_${'width'},h_${'height'},al_${'alignment'}`;
const legacyFillTemplate = template`fill/w_${'width'},h_${'height'},al_${'alignment'}`;

// upscale template
const upscaleTemplate = template`,lg_${'upscaleMethodValue'}`;

// options templates
const qualityTemplate = template`,q_${'quality'}`;
const unSharpMaskTemplate = template`,usm_${'radius'}_${'amount'}_${'threshold'}`;
const nonProgressiveTemplate = template`,bl`;
const watermarkTemplate = template`,wm_${'watermark'}`;
const filterTemplatesMap = {
  [imageFilters.CONTRAST]: template`,con_${'contrast'}`,
  [imageFilters.BRIGHTNESS]: template`,br_${'brightness'}`,
  [imageFilters.SATURATION]: template`,sat_${'saturation'}`,
  [imageFilters.HUE]: template`,hue_${'hue'}`,
  [imageFilters.BLUR]: template`,blur_${'blur'}`,
};
const autoEncodeTemplate = template`,enc_auto`;

/**
 * returns image transform uri
 * @param {object}  transformsObj
 *
 * @returns {string}
 */
function getImageURI(transformsObj: ImageTransformObject): string {
  // construct image transforms
  const transformsObjStrArr: string[] = [];

  // construct transform
  transformsObj.parts.forEach((transformPart: ImageTransformObjectPart) => {
    switch (transformPart.transformType) {
      case transformTypes.CROP:
        transformsObjStrArr.push(cropTemplate(transformPart));
        break;

      case transformTypes.LEGACY_CROP:
        transformsObjStrArr.push(legacyCropTemplate(transformPart));
        break;

      case transformTypes.LEGACY_FILL:
        let legacyFillStr = legacyFillTemplate(transformPart);
        if (transformPart.upscale) {
          legacyFillStr += upscaleTemplate(transformPart);
        }
        transformsObjStrArr.push(legacyFillStr);
        break;

      case transformTypes.FIT:
        let fitStr = fitTemplate(transformPart);
        if (transformPart.upscale) {
          fitStr += upscaleTemplate(transformPart);
        }
        transformsObjStrArr.push(fitStr);
        break;

      case transformTypes.FILL:
        let fillStr = fillTemplate(transformPart);
        if (transformPart.upscale) {
          fillStr += upscaleTemplate(transformPart);
        }
        transformsObjStrArr.push(fillStr);
        break;

      case transformTypes.FILL_FOCAL:
        let fillFocalStr = fillFocalTemplate(transformPart);
        if (transformPart.upscale) {
          fillFocalStr += upscaleTemplate(transformPart);
        }
        transformsObjStrArr.push(fillFocalStr);
        break;
    }
  });

  let transformsStr = transformsObjStrArr.join('/');

  // construct transform options
  // quality
  if (transformsObj.quality) {
    transformsStr += qualityTemplate(transformsObj);
  }
  // un-sharp mask
  if (transformsObj.unsharpMask) {
    transformsStr += unSharpMaskTemplate(transformsObj.unsharpMask);
  }
  // progressive
  if (!transformsObj.progressive) {
    transformsStr += nonProgressiveTemplate(transformsObj);
  }
  // watermark
  if (transformsObj.watermark) {
    transformsStr += watermarkTemplate(transformsObj);
  }
  // filters
  if (transformsObj.filters) {
    transformsStr += Object.keys(transformsObj.filters)
      .map((filterName) =>
        filterTemplatesMap[filterName](transformsObj.filters),
      )
      .join('');
  }
  // auto encode
  if (transformsObj.autoEncode && transformsObj.fileType !== fileType.GIF) {
    transformsStr += autoEncodeTemplate(transformsObj);
  }

  // image url string
  return `${transformsObj.src.id}/${API_VERSION}/${transformsStr}/${transformsObj.fileName}.${transformsObj.preferredExtension}`;
}

export { getImageURI };
