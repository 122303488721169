import { getFeature } from './imageServiceFeatureSupportObject';

/**
 * get a browser detection if running on mobile device
 *
 * @returns {boolean}
 */
function isMobile() {
  return getFeature('isMobile');
}

export { isMobile };
