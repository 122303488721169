import { getURI } from './uri';
import {
  FittingType,
  ImageDataAttributes,
  ImageTransformOptions,
  ImageTransformSource,
  ImageTransformTarget,
} from '../types';

function getSrcset(
  fittingType: FittingType,
  src: ImageTransformSource,
  target: ImageTransformTarget,
  options: ImageTransformOptions,
  data: ImageDataAttributes,
) {
  const dpr = target.pixelAspectRatio || 1;

  return {
    dpr: [
      `${
        dpr === 1
          ? data.uri
          : getURI(
              fittingType,
              src,
              {
                ...target,
                pixelAspectRatio: 1,
              },
              options,
            )
      } 1x`,
      `${
        dpr === 2
          ? data.uri
          : getURI(
              fittingType,
              src,
              {
                ...target,
                pixelAspectRatio: 2,
              },
              options,
            )
      } 2x`,
    ],
  };
}

export { getSrcset };
