import {
  fittingTypes,
  alignTypes,
  transformTypes,
} from '../../helpers/imageServiceConstants';
import {
  getDimension,
  isImageTransformApplicable,
} from '../../helpers/imageServiceUtils';
import type { ImageTransformObject, ImageTransformTarget } from '../../types';

type SVGImageAttributes = {
  css: {
    container: {
      position?: string;
    };
  };
  attr: {
    container: {
      width?: number;
      height?: number;
      viewBox?: string;
    };
    img: {
      x?: number;
      y?: number;
      width?: number | string;
      height?: number | string;
      transform?: string;
      preserveAspectRatio?: string;
    };
  };
};

/* eslint-disable indent */
/**
 * get CSS or SVG attributes to be used in the browser
 * @param {ImageTransformObject}  transformsObj  transform parts object
 * @param {ImageTransformTarget}  target
 *
 * @returns {SVGImageAttributes}
 */
function getSvgAttr(
  transformsObj: ImageTransformObject,
  target: ImageTransformTarget,
): SVGImageAttributes {
  const attributes: SVGImageAttributes = {
    css: {
      container: {},
    },
    attr: {
      container: {},
      img: {},
    },
  };
  const { css, attr } = attributes;
  const { fittingType } = transformsObj;
  const alignType = target.alignment;
  const { width: sourceWidth, height: sourceHeight } = transformsObj.src;
  let imageScale;

  css.container.position = 'relative';

  // populate SVG attributes object
  // eslint-disable-line indent
  switch (fittingType) {
    case fittingTypes.ORIGINAL_SIZE:
    case fittingTypes.LEGACY_ORIGINAL_SIZE:
    case fittingTypes.TILE:
      if (transformsObj.parts && transformsObj.parts.length) {
        attr.img.width = transformsObj.parts[0].width;
        attr.img.height = transformsObj.parts[0].height;
      } else {
        attr.img.width = sourceWidth;
        attr.img.height = sourceHeight;
      }
      attr.img.preserveAspectRatio = 'xMidYMid slice';
      break;

    case fittingTypes.SCALE_TO_FIT:
    case fittingTypes.LEGACY_FIT_WIDTH:
    case fittingTypes.LEGACY_FIT_HEIGHT:
    case fittingTypes.LEGACY_FULL:
      attr.img.width = '100%';
      attr.img.height = '100%';
      attr.img.transform = '';
      attr.img.preserveAspectRatio = '';
      break;

    case fittingTypes.STRETCH:
      attr.img.width = target.width;
      attr.img.height = target.height;
      attr.img.x = 0;
      attr.img.y = 0;
      attr.img.transform = '';
      attr.img.preserveAspectRatio = 'none';
      break;

    case fittingTypes.SCALE_TO_FILL:
      if (!isImageTransformApplicable(transformsObj.src.id)) {
        imageScale = getDimension(
          sourceWidth,
          sourceHeight,
          target.width,
          target.height,
          transformTypes.FILL,
        );
        attr.img.width = imageScale.width;
        attr.img.height = imageScale.height;
      } else {
        attr.img.width = target.width;
        attr.img.height = target.height;
      }
      attr.img.x = 0;
      attr.img.y = 0;
      attr.img.transform = '';
      attr.img.preserveAspectRatio = 'xMidYMid slice';
      break;
  }

  // set alignment for cases where the requested src is smaller or bigger than the target element,
  if (
    typeof attr.img.width === 'number' &&
    typeof attr.img.height === 'number' &&
    (attr.img.width !== target.width || attr.img.height !== target.height)
  ) {
    // x and y to use in svg <pattern> element
    let x = 0;
    let y = 0;
    let right;
    let bottom;
    if (fittingType === fittingTypes.TILE) {
      right = target.width % attr.img.width;
      bottom = target.height % attr.img.height;
    } else {
      right = target.width - attr.img.width;
      bottom = target.height - attr.img.height;
    }
    const center = Math.round(right / 2);
    const middle = Math.round(bottom / 2);

    switch (alignType) {
      case alignTypes.TOP_LEFT:
        x = 0;
        y = 0;
        break;
      case alignTypes.TOP:
        x = center;
        y = 0;
        break;
      case alignTypes.TOP_RIGHT:
        x = right;
        y = 0;
        break;

      case alignTypes.LEFT:
        x = 0;
        y = middle;
        break;
      case alignTypes.CENTER:
        x = center;
        y = middle;
        break;
      case alignTypes.RIGHT:
        x = right;
        y = middle;
        break;

      case alignTypes.BOTTOM_LEFT:
        x = 0;
        y = bottom;
        break;
      case alignTypes.BOTTOM:
        x = center;
        y = bottom;
        break;
      case alignTypes.BOTTOM_RIGHT:
        x = right;
        y = bottom;
        break;
    }

    attr.img.x = x;
    attr.img.y = y;
  }

  attr.container.width = target.width;
  attr.container.height = target.height;
  attr.container.viewBox = [0, 0, target.width, target.height].join(' ');

  // return attributes object
  return attributes;
}
/* eslint-enable indent */

export { getSvgAttr as get };
