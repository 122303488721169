import type {
  TransformFitPart,
  TransformCropPart,
  TransformFillPart,
  TransformLegacyFillPart,
  TransformLegacyFitPart,
  TransformLegacyCropPart,
  ImageTransformObject,
  ImageTransformTarget,
  ImageTransformSource,
} from '../types';
import {
  fittingTypes,
  transformTypes,
  alignTypesMap,
} from './imageServiceConstants';
import {
  getAlignment,
  getScaleFactor,
  getOverlappingRect,
  getAlignedRect,
  getTransformData,
  getFocalPoint,
} from './imageServiceUtils';

/**
 * request analysis, returns parsed transforms object
 * @param {object}                  transformsObj
 * @param {ImageTransformSource}    src
 * @param {ImageTransformTarget}    target
 */
function setTransformParts(
  transformsObj: ImageTransformObject,
  src: ImageTransformSource,
  target: ImageTransformTarget,
) {
  let rect;

  // crop source image if needed
  // set crop part and adjust source dimensions
  if (src.crop) {
    rect = getOverlappingRect(src, src.crop);

    if (rect) {
      transformsObj.src.width = rect.width;
      transformsObj.src.height = rect.height;
      transformsObj.src.isCropped = true;
      transformsObj.parts.push(getCropPart(rect));
    }
  }

  // set additional transform part
  switch (transformsObj.fittingType) {
    case fittingTypes.SCALE_TO_FIT:
    case fittingTypes.LEGACY_FIT_WIDTH:
    case fittingTypes.LEGACY_FIT_HEIGHT:
    case fittingTypes.LEGACY_FULL:
    case fittingTypes.FIT_AND_TILE:
    case fittingTypes.LEGACY_BG_FIT_AND_TILE:
    case fittingTypes.LEGACY_BG_FIT_AND_TILE_HORIZONTAL:
    case fittingTypes.LEGACY_BG_FIT_AND_TILE_VERTICAL:
    case fittingTypes.LEGACY_BG_NORMAL:
      // fit
      transformsObj.parts.push(getFitPart(transformsObj, target));
      break;

    case fittingTypes.SCALE_TO_FILL:
      // fill
      transformsObj.parts.push(getFillPart(transformsObj, target));
      break;

    case fittingTypes.STRETCH:
      // stretch
      transformsObj.parts.push(getStretchPart(transformsObj, target));
      break;

    case fittingTypes.TILE_HORIZONTAL:
    case fittingTypes.TILE_VERTICAL:
    case fittingTypes.TILE:
    case fittingTypes.LEGACY_ORIGINAL_SIZE:
    case fittingTypes.ORIGINAL_SIZE:
      // use crop transform
      // if crop of source image was requested adjust cropping rectangle
      rect = getAlignedRect(
        transformsObj.src,
        target,
        transformsObj.focalPoint,
        target.alignment,
      );

      if (transformsObj.src.isCropped) {
        Object.assign(transformsObj.parts[0], rect);

        // update source width & height accordingly
        transformsObj.src.width = rect.width;
        transformsObj.src.height = rect.height;
      } else {
        transformsObj.parts.push(getCropPart(rect));
      }
      break;

    // ---------------------------------------------------------------------------------------
    // handles a legacy bug on bgImageStrip, background html tag
    // component Full Width Strip stored incorrect image source width and height
    // ---------------------------------------------------------------------------------------
    case fittingTypes.LEGACY_STRIP_TILE_HORIZONTAL:
    case fittingTypes.LEGACY_STRIP_TILE_VERTICAL:
    case fittingTypes.LEGACY_STRIP_TILE:
    case fittingTypes.LEGACY_STRIP_ORIGINAL_SIZE:
      // crop request of source image is not supported
      // use legacy crop
      transformsObj.parts.push(getLegacyCropPart(target));
      break;

    case fittingTypes.LEGACY_STRIP_SCALE_TO_FIT:
    case fittingTypes.LEGACY_STRIP_FIT_AND_TILE:
      // legacy fit
      transformsObj.parts.push(getLegacyFitPart(target));
      break;

    case fittingTypes.LEGACY_STRIP_SCALE_TO_FILL:
      // legacy fill
      transformsObj.parts.push(getLegacyFillPart(target));
      break;
  }
}

/**
 * returns fit part of the image transform uri
 * @param {object}                  transformsObj
 * @param {ImageTransformTarget}    target
 *
 * @returns {TransformFitPart}
 */
function getFitPart(
  transformsObj: ImageTransformObject,
  target: ImageTransformTarget,
): TransformFitPart {
  // calculate the transformed image size needed
  const transformedData = getTransformData(
    transformsObj.src.width,
    transformsObj.src.height,
    transformTypes.FIT,
    target,
    transformsObj.devicePixelRatio,
    transformsObj.upscaleMethod,
  );

  const isMissingSrcDimensions =
    !transformsObj.src.width || !transformsObj.src.height;

  const transformType = isMissingSrcDimensions
    ? transformTypes.FIT
    : transformTypes.FILL;

  // return fit transform data
  return {
    transformType,
    width: Math.round(transformedData.width),
    height: Math.round(transformedData.height),
    alignment: alignTypesMap.center,
    upscale: transformedData.scaleFactor > 1,
    forceUSM: transformedData.forceUSM,
    scaleFactor: transformedData.scaleFactor,
    cssUpscaleNeeded: transformedData.cssUpscaleNeeded,
    upscaleMethodValue: transformedData.upscaleMethodValue,
  };
}

/**
 * returns fill part of the image transform uri
 * @param {ImageTransformObject} transformsObj
 * @param {ImageTransformTarget} target
 *
 * @returns {TransformFillPart}
 */
function getFillPart(
  transformsObj: ImageTransformObject,
  target: ImageTransformTarget,
): TransformFillPart {
  // calculate the transformed image size needed
  const transformedData = getTransformData(
    transformsObj.src.width,
    transformsObj.src.height,
    transformTypes.FILL,
    target,
    transformsObj.devicePixelRatio,
    transformsObj.upscaleMethod,
  );
  const focalPoint = getFocalPoint(transformsObj.focalPoint);
  const transformType = focalPoint
    ? transformTypes.FILL_FOCAL
    : transformTypes.FILL;

  return {
    transformType,
    width: Math.round(transformedData.width),
    height: Math.round(transformedData.height),
    alignment: getAlignment(target),
    focalPointX: focalPoint && focalPoint.x,
    focalPointY: focalPoint && focalPoint.y,
    upscale: transformedData.scaleFactor > 1,
    forceUSM: transformedData.forceUSM,
    scaleFactor: transformedData.scaleFactor,
    cssUpscaleNeeded: transformedData.cssUpscaleNeeded,
    upscaleMethodValue: transformedData.upscaleMethodValue,
  };
}

/**
 * returns fill part of the image transform uri
 * @param {ImageTransformObject} transformsObj
 * @param {ImageTransformTarget} target
 *
 * @returns {TransformFitPart}
 */
function getStretchPart(
  transformsObj: ImageTransformObject,
  target: ImageTransformTarget,
): TransformFitPart {
  // stretch data
  const scaleFactor = getScaleFactor(
    transformsObj.src.width,
    transformsObj.src.height,
    target.width,
    target.height,
    transformTypes.FILL,
  );
  const clonedTarget = { ...target };
  clonedTarget.width = transformsObj.src.width * scaleFactor;
  clonedTarget.height = transformsObj.src.height * scaleFactor;

  // return stretch part
  return getFitPart(transformsObj, clonedTarget);
}

/**
 * returns crop part of the image transform uri
 * @param {{x: number, y: number, width: number, height: number}}  rect     x, y, width, height
 *
 * @returns {TransformCropPart}
 */
function getCropPart(rect: {
  x: number;
  y: number;
  width: number;
  height: number;
}): TransformCropPart {
  return {
    transformType: transformTypes.CROP,
    x: Math.round(rect.x),
    y: Math.round(rect.y),
    width: Math.round(rect.width),
    height: Math.round(rect.height),
    upscale: false,
    forceUSM: false,
    scaleFactor: 1,
    cssUpscaleNeeded: false,
  };
}

// ---------------------------------------------------------------------------------------
// handles a legacy bug on bgImageStrip, background html tag
// component Full Width Strip stored incorrect image source width and height
// ---------------------------------------------------------------------------------------

/**
 * returns fit part of the image transform uri
 * @param {ImageTransformTarget}    target
 *
 * @returns {TransformLegacyFitPart}
 */
function getLegacyFitPart(
  target: ImageTransformTarget,
): TransformLegacyFitPart {
  return {
    transformType: transformTypes.FIT,
    width: Math.round(target.width),
    height: Math.round(target.height),
    upscale: false,
    forceUSM: true,
    scaleFactor: 1,
    cssUpscaleNeeded: false,
  };
}

/**
 * returns fill part of the image transform uri
 * @param {ImageTransformTarget}    target
 *
 * @returns {TransformLegacyFillPart}
 */
function getLegacyFillPart(
  target: ImageTransformTarget,
): TransformLegacyFillPart {
  return {
    transformType: transformTypes.LEGACY_FILL,
    width: Math.round(target.width),
    height: Math.round(target.height),
    alignment: getAlignment(target),
    upscale: false,
    forceUSM: true,
    scaleFactor: 1,
    cssUpscaleNeeded: false,
  };
}

/**
 * returns legacy crop part of the image transform uri
 * @param {ImageTransformTarget}     target
 *
 * @returns {TransformLegacyCropPart}
 */
function getLegacyCropPart(
  target: ImageTransformTarget,
): TransformLegacyCropPart {
  return {
    transformType: transformTypes.LEGACY_CROP,
    width: Math.round(target.width),
    height: Math.round(target.height),
    alignment: getAlignment(target),
    upscale: false,
    forceUSM: false,
    scaleFactor: 1,
    cssUpscaleNeeded: false,
  };
}

export { setTransformParts };
