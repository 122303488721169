import { alignTypes, fittingTypes } from '../../helpers/imageServiceConstants';
import type { ImageTransformObject, ImageTransformTarget } from '../../types';

type ImageAttributes = {
  css: {
    container: {
      position?: string;
    };
    img: {
      width?: number | string;
      height?: number | string;
      objectFit?: string;
      objectPosition?: string;
    };
  };
};

/**
 * align type to position
 * @param verticalMiddle
 * @param horizontalMiddle
 * @param target
 * @returns {{}}
 */
function alignTypeToPosition(
  verticalMiddle: number,
  horizontalMiddle: number,
  target: ImageTransformTarget,
) {
  return {
    [alignTypes.TOP_LEFT]: { top: 0, left: 0 },
    [alignTypes.TOP_RIGHT]: { top: 0, right: 0 },
    [alignTypes.TOP]: { top: 0, left: horizontalMiddle },
    [alignTypes.BOTTOM_LEFT]: { bottom: 0, left: 0 },
    [alignTypes.BOTTOM_RIGHT]: { bottom: 0, right: 0 },
    [alignTypes.BOTTOM]: { bottom: 0, left: horizontalMiddle },
    [alignTypes.RIGHT]: { top: verticalMiddle, right: 0 },
    [alignTypes.LEFT]: { top: verticalMiddle, left: 0 },
    [alignTypes.CENTER]: {
      width: target.width,
      height: target.height,
      objectFit: 'none',
    },
  };
}

const alignTypeToPositionStr = {
  [alignTypes.CENTER]: 'center',
  [alignTypes.TOP]: 'top',
  [alignTypes.TOP_LEFT]: 'top left',
  [alignTypes.TOP_RIGHT]: 'top right',
  [alignTypes.BOTTOM]: 'bottom',
  [alignTypes.BOTTOM_LEFT]: 'bottom left',
  [alignTypes.BOTTOM_RIGHT]: 'bottom right',
  [alignTypes.LEFT]: 'left',
  [alignTypes.RIGHT]: 'right',
};

const aligmentDefaults = {
  position: 'absolute',
  top: 'auto',
  right: 'auto',
  bottom: 'auto',
  left: 'auto',
};

/**
 * returns image tag CSS data
 * @param {ImageTransformObject}    transformsObj    transform parts object
 * @param {ImageTransformTarget}    target
 *
 * @returns {ImageAttributes}
 */
function getCSS(
  transformsObj: ImageTransformObject,
  target: ImageTransformTarget,
): ImageAttributes {
  const attributes: ImageAttributes = {
    css: {
      container: {},
      img: {},
    },
  };
  const { css } = attributes;
  const { fittingType } = transformsObj;
  const alignType = target.alignment;

  css.container.position = 'relative';

  switch (fittingType) {
    case fittingTypes.ORIGINAL_SIZE:
    case fittingTypes.LEGACY_ORIGINAL_SIZE:
      if (transformsObj.parts && transformsObj.parts.length) {
        css.img.width = transformsObj.parts[0].width;
        css.img.height = transformsObj.parts[0].height;
      } else {
        css.img.width = transformsObj.src.width;
        css.img.height = transformsObj.src.height;
      }
      break;

    case fittingTypes.SCALE_TO_FIT:
    case fittingTypes.LEGACY_FIT_WIDTH:
    case fittingTypes.LEGACY_FIT_HEIGHT:
    case fittingTypes.LEGACY_FULL:
      css.img.width = target.width;
      css.img.height = target.height;
      css.img.objectFit = 'contain';
      css.img.objectPosition =
        alignTypeToPositionStr[alignType as string] || 'unset';
      break;
    // BG_NORMAL is a sitebackground legacy for original size fitting type.
    // target width and height were modified in transform.js
    case fittingTypes.LEGACY_BG_NORMAL:
      css.img.width = '100%';
      css.img.height = '100%';
      css.img.objectFit = 'none';
      css.img.objectPosition =
        alignTypeToPositionStr[alignType as string] || 'unset';
      break;

    case fittingTypes.STRETCH:
      css.img.width = target.width;
      css.img.height = target.height;
      css.img.objectFit = 'fill';
      break;

    case fittingTypes.SCALE_TO_FILL:
      css.img.width = target.width;
      css.img.height = target.height;
      css.img.objectFit = 'cover';
      break;
  }

  // set alignment in a private case where the image src is smaller than the image container,
  if (
    typeof css.img.width === 'number' &&
    typeof css.img.height === 'number' &&
    (css.img.width !== target.width || css.img.height !== target.height)
  ) {
    const verticalMiddle = Math.round((target.height - css.img.height) / 2);
    const horizontalMiddle = Math.round((target.width - css.img.width) / 2);
    Object.assign(
      css.img,
      aligmentDefaults,
      alignTypeToPosition(verticalMiddle, horizontalMiddle, target)[
        alignType as string
      ],
    );
  }

  return attributes;
}

export { getCSS as get };
