import { last } from './utils';
import { getPreferredImageQuality, roundToFixed } from './imageServiceUtils';
import {
  defaultUSM,
  fileType,
  imageFilters,
  transformTypes,
} from './imageServiceConstants';
import type {
  ImageTransformFiltersOption,
  ImageTransformObject,
  ImageTransformOptions,
  OptionUnsharpMask,
  TemplateUnsharpMask,
} from '../types';

/**
 * returns image filters part of the image transform uri
 * @param {ImageTransformObject}    transformsObj    transform parts object
 * @param {ImageTransformOptions}   [options]
 */
function setTransformOptions(
  transformsObj: ImageTransformObject,
  options?: ImageTransformOptions,
) {
  options = options || {};
  // options - general
  transformsObj.quality = getQuality(transformsObj, options);
  transformsObj.progressive = getProgressive(options);
  transformsObj.watermark = getWatermark(options);
  transformsObj.autoEncode = options.autoEncode ?? true;
  // options - filters & adjustments
  transformsObj.unsharpMask = getUnsharpMask(transformsObj, options);
  transformsObj.filters = getFilters(options);
}

/**
 *
 * @param {ImageTransformOptions}   options
 * @returns {string}
 */
function getWatermark(options: ImageTransformOptions) {
  return options.watermark;
}

/**
 * returns progressive if required
 * @param {ImageTransformOptions}   options
 *
 * @returns {boolean}
 */
function getProgressive(options: ImageTransformOptions) {
  return options.progressive !== false;
}

/**
 * returns image filters part of the image transform uri
 * @param {ImageTransformObject}    transformsObj    transform parts object
 * @param {ImageTransformOptions}   options
 *
 * @returns {number}
 */
function getQuality(
  transformsObj: ImageTransformObject,
  options: ImageTransformOptions,
) {
  const isPNG = transformsObj.fileType === fileType.PNG;
  const isJPG = transformsObj.fileType === fileType.JPG;
  const isWEBP = transformsObj.fileType === fileType.WEBP;

  const isQualitySupported = isJPG || isPNG || isWEBP;
  if (isQualitySupported) {
    const transformData = last(transformsObj.parts);

    const defaultQuality = getPreferredImageQuality(
      transformData.width,
      transformData.height,
    );
    let quality =
      options.quality && options.quality >= 5 && options.quality <= 90
        ? options.quality
        : defaultQuality;
    // increase quality by 5 for webp images
    quality = isPNG ? quality + 5 : quality;
    return quality;
  }
  // quality not supported
  return 0;
}

/**
 * returns the desired transformed image filters
 * @param {ImageTransformOptions}   options
 *
 * @returns {object}
 */
function getFilters(options: ImageTransformOptions) {
  const filterOptions: ImageTransformFiltersOption = options.filters || {};
  const filters: ImageTransformFiltersOption = {};

  // contrast
  if (isValidImageFilter(filterOptions[imageFilters.CONTRAST], -100, 100)) {
    filters[imageFilters.CONTRAST] = filterOptions[imageFilters.CONTRAST];
  }

  // brightness
  if (isValidImageFilter(filterOptions[imageFilters.BRIGHTNESS], -100, 100)) {
    filters[imageFilters.BRIGHTNESS] = filterOptions[imageFilters.BRIGHTNESS];
  }

  // saturation
  if (isValidImageFilter(filterOptions[imageFilters.SATURATION], -100, 100)) {
    filters[imageFilters.SATURATION] = filterOptions[imageFilters.SATURATION];
  }

  // hue
  if (isValidImageFilter(filterOptions[imageFilters.HUE], -180, 180)) {
    filters[imageFilters.HUE] = filterOptions[imageFilters.HUE];
  }

  // blur
  if (isValidImageFilter(filterOptions[imageFilters.BLUR], 0, 100)) {
    filters[imageFilters.BLUR] = filterOptions[imageFilters.BLUR];
  }

  return filters;
}

/**
 * indicates if requested filter value is valid
 * @param {number|undefined}  filterValue     filter's value
 * @param {number}  minValue        min range
 * @param {number}  maxValue        max range
 *
 * @returns {boolean}
 */
function isValidImageFilter(
  filterValue: number | undefined,
  minValue: number,
  maxValue: number,
) {
  // check if filter name and filter values range valid
  return (
    typeof filterValue === 'number' &&
    !isNaN(filterValue) &&
    filterValue !== 0 &&
    filterValue >= minValue &&
    filterValue <= maxValue
  );
}

/**
 * returns the desired transformed image unSharpMask values
 * @param {ImageTransformObject}    transformsObj    transform parts object
 * @param {ImageTransformOptions}   options
 *
 * @returns {object}
 */
function getUnsharpMask(
  transformsObj: ImageTransformObject,
  options: ImageTransformOptions,
): TemplateUnsharpMask | undefined {
  // If options.unsharpMask is a valid value, use it
  if (isUSMValid(options.unsharpMask)) {
    // If we got usm, change values to have trailing zeros (.00), else return undefined
    return {
      radius: roundToFixed(options.unsharpMask?.radius!, 2),
      amount: roundToFixed(options.unsharpMask?.amount!, 2),
      threshold: roundToFixed(options.unsharpMask?.threshold!, 2),
    };
    // if options.unsharpMask is not all zeros and not valid and usm should be used, use default
  } else if (!isZeroUSM(options.unsharpMask) && isUSMNeeded(transformsObj)) {
    return defaultUSM;
  }

  return;
}

/**
 * indicates if usm is needed
 * @param {ImageTransformObject}      transformsObj   transform parts object
 *
 * @returns {boolean}
 */
function isUSMNeeded(transformsObj: ImageTransformObject) {
  // ---------------------------------------------------------------------------------------
  // do not apply usm if transformed image width & height is same as source image or larger
  // and no force usm is desired
  // and transform type is not fit
  // ---------------------------------------------------------------------------------------
  const transformPart = last(transformsObj.parts);
  const upscale = transformPart.scaleFactor >= 1;

  // return if usm is needed
  return (
    !upscale ||
    transformPart.forceUSM ||
    transformPart.transformType === transformTypes.FIT
  );
}

/**
 * indicates if all usm values are presented and in range
 * @param {OptionUnsharpMask|undefined}  usm     unsharp mask
 *
 * @returns {boolean}
 */
function isUSMValid(usm: OptionUnsharpMask | undefined) {
  usm = usm || ({} as OptionUnsharpMask);
  const radius =
    typeof usm.radius === 'number' &&
    !isNaN(usm.radius) &&
    usm.radius >= 0.1 &&
    usm.radius <= 500;
  const amount =
    typeof usm.amount === 'number' &&
    !isNaN(usm.amount) &&
    usm.amount >= 0 &&
    usm.amount <= 10;
  const threshold =
    typeof usm.threshold === 'number' &&
    !isNaN(usm.threshold) &&
    usm.threshold >= 0 &&
    usm.threshold <= 255;

  // return is a valid USM data
  return radius && amount && threshold;
}

/**
 * indicates if all usm values are presented and are zero. an explicit request to not apply usm
 * @param {OptionUnsharpMask|undefined}  usm     unsharp mask
 *
 * @returns {boolean}
 */
function isZeroUSM(usm: OptionUnsharpMask | undefined) {
  usm = usm || ({} as OptionUnsharpMask);
  return (
    typeof usm.radius === 'number' &&
    !isNaN(usm.radius) &&
    usm.radius === 0 &&
    typeof usm.amount === 'number' &&
    !isNaN(usm.amount) &&
    usm.amount === 0 &&
    typeof usm.threshold === 'number' &&
    !isNaN(usm.threshold) &&
    usm.threshold === 0
  );
}

export { setTransformOptions };
