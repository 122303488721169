import {
  populateGlobalFeatureSupport,
  getScaleToFitImageURL,
  getScaleToFillImageURL,
  getCropImageURL,
  wixStatic,
  wixStaticWithMedia,
} from './api';

populateGlobalFeatureSupport();

export {
  getScaleToFitImageURL,
  getScaleToFillImageURL,
  getCropImageURL,
  wixStatic,
  wixStaticWithMedia,
};
