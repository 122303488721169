import { alignTypes, fittingTypes } from './imageServiceConstants';
import { isPNG } from './imageServiceUtils';
import type {
  FittingType,
  Dimensions,
  ImageTransformSource,
  Point,
} from '../types';

const ALIGN_TYPE_TO_POSITION = {
  [alignTypes.CENTER]: '50% 50%',
  [alignTypes.TOP_LEFT]: '0% 0%',
  [alignTypes.TOP_RIGHT]: '100% 0%',
  [alignTypes.TOP]: '50% 0%',
  [alignTypes.BOTTOM_LEFT]: '0% 100%',
  [alignTypes.BOTTOM_RIGHT]: '100% 100%',
  [alignTypes.BOTTOM]: '50% 100%',
  [alignTypes.RIGHT]: '100% 50%',
  [alignTypes.LEFT]: '0% 50%',
};

const POSITION_TO_ALIGN_TYPE = Object.entries(ALIGN_TYPE_TO_POSITION).reduce(
  (acc: Record<string, string>, [align, position]) => {
    acc[position] = align;
    return acc;
  },
  {},
);

const TILE_FITTING_TYPES = [
  fittingTypes.TILE,
  fittingTypes.TILE_HORIZONTAL,
  fittingTypes.TILE_VERTICAL,
  fittingTypes.LEGACY_BG_FIT_AND_TILE,
  fittingTypes.LEGACY_BG_FIT_AND_TILE_HORIZONTAL,
  fittingTypes.LEGACY_BG_FIT_AND_TILE_VERTICAL,
];

const NON_SCALING_FITTING_TYPES = [
  fittingTypes.LEGACY_ORIGINAL_SIZE,
  fittingTypes.ORIGINAL_SIZE,
  fittingTypes.LEGACY_BG_NORMAL,
];

function getIsFakeTile(
  fittingType: string,
  src: ImageTransformSource,
  {
    width: targetWidth,
    height: targetHeight,
  }: { width: number; height: number },
) {
  return (
    fittingType === fittingTypes.TILE &&
    src.width > targetWidth &&
    src.height > targetHeight
  );
}
/**
 *
 * @param {FittingType}             fittingType      imageServicesTypes.fittingTypes
 * @param {ImageTransformSource}    src              source image
 * @param {Dimensions}              target           target container dimensions
 * @param {boolean}                 [isSEOBot=false] whether it is a render for a bot request
 * @returns {Dimensions} scaled dimensions
 */
function getScaledDimensions(
  fittingType: FittingType,
  src: ImageTransformSource,
  { width, height }: Dimensions,
  isSEOBot = false,
) {
  if (isSEOBot) {
    return { width, height };
  }

  const isScalable = !NON_SCALING_FITTING_TYPES.includes(fittingType);
  const isFakeTile = getIsFakeTile(fittingType, src, { width, height });
  const isTile = !isFakeTile && TILE_FITTING_TYPES.includes(fittingType);
  const _width = isTile ? src.width : width;
  const _height = isTile ? src.height : height;
  const scaleFactor = isScalable
    ? getScaleFactorByWidth(_width, isPNG(src.id))
    : 1;

  return {
    width: isFakeTile ? 1920 : _width * scaleFactor,
    height: _height * scaleFactor,
  };
}

/**
 *
 * @param {FittingType} fittingType  imageServicesTypes.fittingTypes
 * @param {boolean} isFakeTile
 * @returns {string} fittingType
 */
function getConvertedFitting(fittingType: FittingType, isFakeTile: boolean) {
  const isTile = TILE_FITTING_TYPES.includes(fittingType) && !isFakeTile;
  const isFill = fittingType === fittingTypes.SCALE_TO_FILL;
  return isFill || isTile ? fittingTypes.SCALE_TO_FIT : fittingType;
}

/**
 *
 * @param {ImageTransformSource}    src                 source image
 * @param {number || ''}            width
 * @param {number || ''}            height
 * @returns {Dimensions}    width, height
 */
function validateTargetDimensions(
  src: ImageTransformSource,
  { width, height }: { width?: number; height?: number },
) {
  if (!width || !height) {
    const _width = width || Math.min(980, src.width);
    const heightRatio = _width / src.width;
    return {
      width: _width,
      height: height || src.height * heightRatio,
    };
  }
  return { width, height };
}

/**
 *
 * @param {number} width
 * @param {boolean} isHighQuality
 * @return {number}
 */
function getScaleFactorByWidth(width: number, isHighQuality: boolean) {
  if (width > 900) {
    return isHighQuality ? 0.05 : 0.15;
  } else if (width > 500) {
    return isHighQuality ? 0.1 : 0.18;
  } else if (width > 200) {
    return 0.25;
  }

  return 1;
}

/**
 *
 * @param {number} width
 * @param {FittingType} fittingType imageServicesTypes.fittingTypes
 * @param {boolean} isSEOBot
 * @return {number}
 */
function getBlurValue(
  width: number,
  fittingType: FittingType,
  isSEOBot?: boolean,
) {
  if (isSEOBot) {
    return 0;
  }
  if (TILE_FITTING_TYPES.includes(fittingType)) {
    return 1;
  }

  if (width > 200) {
    return 2;
  }

  return 3;
}

/**
 *
 * @param {FittingType}                       fittingType
 * @param {ImageTransformSource}              src                  source image
 * @param {{width?: number; height?: number}} target               target element
 * @param {string}                            [alignment='center']
 * @returns {{img}, {container}}
 */
function getCSSOverrides(
  fittingType: FittingType,
  src: ImageTransformSource,
  target: { width?: number; height?: number },
  alignment = 'center',
) {
  const returnValue = {
    img: {},
    container: {},
  };

  if (fittingType === fittingTypes.SCALE_TO_FILL) {
    const alignTypeFromFocalPoint =
      src.focalPoint && convertFocalPointToAlignType(src.focalPoint as Point);
    const alignType = alignTypeFromFocalPoint || alignment;

    if (src.focalPoint && !alignTypeFromFocalPoint) {
      returnValue.img = {
        objectPosition: convertFillFocalToPosition(
          src,
          target,
          src.focalPoint as Point,
        ),
      };
    } else {
      returnValue.img = {
        objectPosition: ALIGN_TYPE_TO_POSITION[alignType],
      };
    }
  } else if (
    [fittingTypes.LEGACY_ORIGINAL_SIZE, fittingTypes.ORIGINAL_SIZE].includes(
      fittingType,
    )
  ) {
    returnValue.img = {
      objectFit: 'none',
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
    };
  } else if (TILE_FITTING_TYPES.includes(fittingType)) {
    returnValue.container = {
      backgroundSize: `${src.width}px ${src.height}px`,
    };
  }

  return returnValue;
}

/**
 * Try to convert focal point value to 9 grid alignment value
 *
 * @param {Point}    focalPoint
 * @returns {AlignType} align type
 */
function convertFocalPointToAlignType(focalPoint: Point) {
  const position = `${focalPoint.x}% ${focalPoint.y}%`;

  return POSITION_TO_ALIGN_TYPE[position] || '';
}

/**
 *
 * @param {Dimensions} src source dimensions
 * @param {width?: number; height?: number} target target dimensions
 * @param {Point} focalPoint x/y as 0-100 percentages
 * @returns {string} in 'x% y%' format
 */
function convertFillFocalToPosition(
  src: Dimensions,
  target: { width?: number; height?: number },
  focalPoint: Point,
) {
  const { width: sW, height: sH } = src;
  const { width: tW, height: tH } = target;
  const { x: fpX, y: fpY } = focalPoint;

  if (!tW || !tH) {
    return `${fpX}% ${fpY}%`;
  }

  const fillScaleFactor = Math.max(tW / sW, tH / sH);

  const imgScaledW = sW * fillScaleFactor;
  const imgScaledH = sH * fillScaleFactor;

  const x = Math.max(
    0,
    Math.min(imgScaledW - tW, imgScaledW * (fpX / 100) - tW / 2),
  );
  const y = Math.max(
    0,
    Math.min(imgScaledH - tH, imgScaledH * (fpY / 100) - tH / 2),
  );

  const posX = x && Math.floor((x / (imgScaledW - tW)) * 100);
  const posY = y && Math.floor((y / (imgScaledH - tH)) * 100);

  return `${posX}% ${posY}%`;
}

export {
  getBlurValue,
  getConvertedFitting,
  getCSSOverrides,
  getIsFakeTile,
  getScaledDimensions,
  validateTargetDimensions,
};
